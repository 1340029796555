import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Wrap from '../components/Wrap';
import H1 from '../components/H1';
import Paragraph from '../components/Paragraph';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrap>
      <H1 css={{ textAlign: 'left' }}>NOT FOUND</H1>
      <Paragraph>
        This page does not exist.{' '}
        <Link to="/" css={{ textDecoration: 'underline', color: 'inherit' }}>
          Return to the homepage.
        </Link>
      </Paragraph>
    </Wrap>
  </Layout>
);

export default NotFoundPage;
